import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { ProvideEnvironment } from '@rxap/environment';
import { HttpErrorInterceptor, ProvideErrorHandler } from '@rxap/ngx-error';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { CompanySelectInterceptor } from 'angular-http-interceptors/company-select-interceptor';
import { provideServiceWorker } from '@angular/service-worker';
import { KeycloakBearerInterceptor } from '@rxap/keycloak';
import {
  AuthHttpInterceptor,
  CorsInterceptor,
  ProvideAuth,
  ServiceServerApiKeyHttpInterceptor,
} from 'eurogard-authentication';
import { ProvideI18nMatIntl } from 'angular-components/localize/provide';
import { ProvideErrorInterceptorOptions } from 'angular-application-providers/provide-error-interceptor-options';
import { MarkdownModule } from 'ngx-markdown';
import { LanguageInterceptor } from '@rxap/ngx-localize';
import { ProvideServiceWorkerUpdater, withDialogUpdater, withLogUpdater } from '@rxap/service-worker';
import { ProvidePubSub } from '@rxap/ngx-pub-sub';
import { ProvideDate } from 'angular-application-providers/provide-date';
import { ProvideSetLanguageHook } from 'angular-application-providers/provide-set-language-hook';
import { ProvideIconAssetPath } from '@rxap/icon';
import { provideExternalAppsFilter } from 'angular-application-providers/provide-external-apps-filter';
import { provideTheme } from 'angular-services/theme/provide';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(MarkdownModule.forRoot()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // required to be imported so that the http client is available in the application for the status check method
    provideHttpClient(
      withInterceptors([
        HttpErrorInterceptor,
        CompanySelectInterceptor,
        AuthHttpInterceptor,
        CorsInterceptor,
        KeycloakBearerInterceptor,
        ServiceServerApiKeyHttpInterceptor,
        LanguageInterceptor,
      ]),
    ),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    ProvideErrorHandler(),
    ProvideAuth(),
    ProvideI18nMatIntl(),
    ProvideIconAssetPath(),
    ProvideErrorInterceptorOptions(),
    ProvideEnvironment(environment),
    ProvideServiceWorkerUpdater(withLogUpdater(), withDialogUpdater()),
    ProvidePubSub(),
    ProvideDate(),
    ProvideSetLanguageHook(),
    provideExternalAppsFilter(),
    provideTheme(),
  ],
};
